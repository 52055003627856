.collapse-filter-title {
    display: flex;
    margin-bottom: 36px;
    border-bottom: 1px solid rgba(#353c45, 0.16);
    line-height: 1.62;
    font-size: 1.3125rem;
    letter-spacing: -1.05px;
    max-width: 200px;
    padding-bottom: 28px;
    margin-bottom: 24px;
}
