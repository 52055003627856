.authors-hero {
    margin-bottom: calc(80px);

    @include md-up {
        margin-bottom: calc(320px + 115px);
    }

    @include lg-up {
        margin-bottom: calc(120px + 115px);
    }
}

.authors-hero-box {
    padding: 40px 28px;
    margin-top: 20px;
    margin-bottom: 80px;

    @include md-up {
        position: absolute;
        right: 0;
        bottom: -315px;
        padding: 74px 54px;
        margin: 0 20px;
        margin-bottom: 0;
        max-width: 735px;
        z-index: 2;
        background-color: white;
    }

    @include lg-up {
        bottom: -115px;
    }

    .authors-hero-box-title {
        color: $primary;
        text-align: center;
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 24px;

        @include md-up {
            font-size: 3rem;
            text-align: left;
            margin-bottom: 30px;
            font-weight: 500;
        }
    }

    p {
        line-height: 30px;
    }
}

.author-item {
    margin-bottom: 80px;

    @include lg-up {
        padding: 80px 0;
        border-top: 1px solid rgba(#707070, 0.2);
        margin-bottom: 0;
    }

    .author-item-first-col {
        margin-bottom: 32px;
        width: 100%;

        @include lg-up {
            width: 200px;
        }
    }

    .author-item-image {
        margin-bottom: 32px;
        height: auto;
        object-fit: cover;
        object-position: top center;
        border-radius: 6px;
        width: 100%;

        @include sm-up {
            width: 200px;
            height: 200px;
        }

        @include lg-up {
            height: 200px;
        }
    }

    .author-item-title {
        font-size: 1.25rem;
        color: $primary;
        font-weight: 500;
        margin-bottom: 20px;

        @include lg-up {
            font-size: 1.5rem;
        }
    }

    .author-certifications-container {
        @include lg-up {
            padding-left: 100px;
        }
    }

    .author-certifications {
        margin-right: -16px;
        max-width: 600px;

        img {
            width: calc(50% - 20px);
            margin-right: 16px;
            margin-bottom: 16px;
            object-fit: cover;

            @include sm-up {
                width: calc(33% - 20px);
            }

            @include md-up {
                width: calc(25% - 20px);
            }

            @include lg-up {
                width: 120px;
                height: 85px;
            }
        }
    }

    .author-certifications-title {
        margin-bottom: 24px;
        font-size: 1.3125rem;

        @include lg-up {
            margin-top: 1.5rem;
        }
    }

    .icon-box {
        display: flex;
        justify-content: center;
        min-width: 28px;
        margin-right: 20px;
        position: relative;
    }

    .author-item-item {
        margin-bottom: 12px;

        &:first-child {
            margin-top: 40px;
        }

        &:last-child {
            margin-bottom: 40px;
        }
    }

    .author-item-info {
        @include lg-up {
            padding-left: 100px;

            p {
                max-width: 600px;
            }
        }
    }

    .btn-primary {
        @include lg-up {
            width: 100%;
        }
    }
}

.authors-hero-img {
    max-width: 1000px;
}
