.section {
    margin-bottom: 100px;

    @include md-up {
        margin-bottom: 120px;
    }

    
    @include lg-up {
        margin-bottom: 120px;
    }

    @include xxl-up {
        margin-bottom: 160px;
    }

    &.bg-light {
        padding: 100px 0;

        @include md-up {
            padding: 120px 0;
        }

        
        @include lg-up {
            padding: 140px 0;
        }

        @include xxl-up {
            padding: 160px 0;
        }
    }
}
