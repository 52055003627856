.info-box {
    padding: 72px 32px;
    margin-bottom: 80px;

    @include lg-up {
        padding: 32px;
        margin-top: 40px;
        margin-bottom: 120px;
    }
}

.success-alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;

    .success-alert-title {
        font-size: 24px;
        font-weight: 500;
        margin-top: 28px;
    }
}

.alert {
    line-height: 1.2 !important;
}

.alert-container {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
}

.alert-danger {
    background: #ffeded;
    border-radius: 10px;
    border-color: #ffeded;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #353535;
}
