.product-help {
    border-top: 1px solid rgba(#353c45, 0.15);
    padding-top: 24px;
    margin-top: 24px;
}

.product-help-img {
    width: 94px;
    height: 120px;
    object-fit: cover;
    border-radius: 6px;
}

.product-video-container {
    .video {
        border-radius: 12px;
    }
}

.product-subtitle {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.3;
    letter-spacing: -1px;
    text-align: left;
    color: #353c45;
}

.product-text {
    line-height: 1.88;
    letter-spacing: -0.48px;
    color: #353c45;
}

.product-box {
    margin-bottom: 60px;

    &:first-child {
        margin-top: 60px;
    }

    @include lg-up {
        margin-bottom: 75px;

        &:first-child {
            margin-top: 30px;
        }
    }
}

.certificate-img {
    width: 160px;
    min-width: 160px;
    height: 113px;
    box-shadow: 3px 3px 10px 0 rgba(15, 17, 48, 0.1);
    margin-bottom: 28px;
    object-fit: cover;

    @include lg-up {
        margin-right: 32px;
        margin-bottom: 0;
    }
}

.author-img {
    width: 100%;
    height: 150px;
    margin-bottom: 28px;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;

    @include lg-up {
        width: 155px;
        height: 150px;
        margin-right: 40px;
        margin-bottom: 0;
    }
}

.author-img-small {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 16px;
}

.timeline {
    position: relative;

    &:not(.show-all) {
        &:after {
            position: absolute;
            bottom: 0;
            display: block;
            content: '';
            width: 100%;
            height: 100px;
            background: linear-gradient(0deg, $light 30%, rgba(255, 255, 255, 0) 100%);
        }
    }

    p {
        line-height: 1.88;
        letter-spacing: -0.48px;
        text-align: left;
        color: #353c45;
    }
}

.product-buy-mobile {
    position: fixed;
    bottom: 0;
    padding: 14px 22px;
    box-shadow: 0 0 20px 0 rgba(14, 11, 49, 0.2);
}

.page-detail {
    padding-bottom: 78px;

    @include xl-up {
        padding-bottom: 0;
    }
}

.collapse-lecture {
    border-radius: 12px;
    padding: 16px 28px;
    box-shadow: 3px 3px 20px rgba(#0a0d38, 0.16);
    background: white;
    margin-bottom: 20px;
    transform: .2s all;

    &:hover {
        box-shadow: 3px 3px 20px rgba(#0a0d38, 0.32);
    }

    @include lg-up {
        padding: 32px 52px;

        .faq-collapse {
            padding-bottom: 10px;
        }
    }

    &.bg-success,
    &.bg-error {
        background-color: #41af79 !important;
        color: white !important;

        .collapse-icon {
            border: solid 1px #fff;

            &:after {
                content: '';
                display: block;
                background: url(/dist/img/icons/arrow_left_icon_white.svg) center no-repeat;
                transform: rotate(-90deg);
                width: 8px;
                height: 10px;
            }

            &:hover {
                background-color: #fff;

                &:after {
                    background: url(/dist/img/icons/arrow_left_icon_grey.svg) center no-repeat;
                    transform: rotate(-90deg);
                }
            }
        }

        p, h4 {
            color: white;
        }


    }

    &.bg-error {
        background-color: #D14142 !important;
    }
}


.gallery-slider-big-container {

    .tns-controls {
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;
    }
}

.gallery-slider {

    img {
        width: 155px;
        height: 90px;
        object-fit: cover;
    }
}

.gallery-slider-big {

    img {
        width: 155px;
        height: 90px;
        object-fit: cover;

        @include md-up {
            width: 400px;
            height: 230px;
        }
    }
}