@font-face {
    font-family: 'Objectivity';
    src: url('/dist/fonts/Objectivity-Regular.otf') format('opentype'),
        url('/dist/fonts/Objectivity-Regular.woff') format('woff');
    font-weight: 400;
    ascent-override: 100%;
}

@font-face {
    font-family: 'Objectivity';
    src: url('/dist/fonts/Objectivity-Medium.otf') format('opentype'),
        url('/dist/fonts/Objectivity-Medium.woff') format('woff');
    font-weight: 500;
    ascent-override: 100%;
}

@font-face {
    font-family: 'Objectivity';
    src: url('/dist/fonts/Objectivity-Bold.otf') format('opentype'),
        url('/dist/fonts/Objectivity-Bold.woff') format('woff');
    font-weight: 700;
    ascent-override: 100%;
}

body {
    font-family: 'Objectivity' !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
    color: #353c45;
    font-family: 'Objectivity' !important;
}

p {
    line-height: 1.88;
}

span {
    // line-height: 1;
}

.text-title {
    font-size: 28px;
    font-weight: 500;
    color: #353c45;
    line-height: 1.21;
    letter-spacing: -1.4px;
    margin-bottom: 28px;

    @include md-up {
        font-size: 72px;
        line-height: 1.04;
        letter-spacing: -3.6px;
        margin-bottom: 72px;
    }

    strong,
    b {
        font-weight: 500 !important;
        color: $primary !important;
    }
}

.text-subtitle {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.21;
    letter-spacing: -1.4px;
    color: #353c45;
    margin-bottom: 32px;

    @include md-up {
        font-size: 3rem;
    }

    strong,
    b {
        font-weight: 500 !important;
        color: $primary !important;
    }
}

.fw-bolder {
    font-weight: 500 !important;

    strong,
    b {
        font-weight: 500 !important;
    }
}

.text-muted {
    color: rgba(#353c45, 0.4) !important;
}

.text-light {
    color: rgba(#353c45, 0.6) !important;
}

.text-gold {
    color: $gold !important;
}

.text-grey-dark {
    color: $grey-dark !important;
}

.smaller {
    font-size: 0.875rem;
}

.smallest {
    font-size: 0.75rem;
}

.fs-20 {
    font-size: 1.25rem;
    line-height: 26px;
}

.fs-24 {
    font-size: 1.25rem;
    line-height: 26px;

    &.nr {
        font-size: 1.25rem;
        line-height: 26px;
    }

    @include md-up {
        font-size: 1.5rem;
        line-height: 34px;
    }
}

.fs-12 {
    font-size: 0.75rem;
}

.fw-bold-primary {
    b,
    strong {
        color: $primary !important;
    }
}
