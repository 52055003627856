.custom-nav {
    list-style: none;

    li {
        display: flex;
        align-items: flex-start;
        line-height: 1.88;
        margin-bottom: 16px;

        &:before {
            content: '';
            background-color: $primary;
            width: 8px;
            min-width: 8px;
            height: 8px;
            min-height: 8px;
            margin-left: -1.25rem;
            border-radius: 50%;
            margin-right: 20px;
            margin-top: 12px;
        }
    }
}
