.map-container {
    iframe {
        height: 140px;
        border-radius: 12px;
        margin: 10px 0px;

        @include md-up {
            height: 350px;
        }
    }
}

#map {
    height: 140px;
    border-radius: 12px;
    margin: 10px 0px;
    border: 1px solid $light;

    @include md-up {
        height: 350px;
    }
}
