// Media Queries 

@mixin xxs-up {
	@media (min-width: 375px) { @content; }
}

@mixin xs-up {
	@media (min-width: 420px) { @content; }
}

@mixin sm-up {
	@media (min-width: 576px) { @content; }
}

@mixin md-up {
	@media (min-width: 767.98px) { @content; }
}

@mixin lg-up {
	@media (min-width: 992px) { @content; }
}

@mixin lg-down {
	@media (max-width: 992px) { @content; }
}

@mixin xl-up {
	@media (min-width: 1200px) { @content; }
}

@mixin xxl-up {
	@media (min-width: 1400px) { @content; }
}

// Width
@mixin size($width, $height) {
	width: $width;
	min-width: $width;
	height: $height;
	min-height: $height;
}

// Icons
@mixin bordered-icon($name, $border-color, $width, $height) {
	border: 1px solid $border-color;
	border-radius: $border-radius;
	width: 30px;
	height: 30px;

	&:after {
		content: "";
		display: block;
		width: $width;
		height: $height;
		background: url("/dist/img/icons/#{$name}") center no-repeat;
		background-size: contain;
		background-position: center;
	}
}

@mixin rounded-icon($name, $width, $height, $color: white, $bg-color: transparent) {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid $color;
	background-color: $bg-color;
	border-radius: 50%;
	width: 48px;
	height: 48px;

	&:after {
		content: "";
		display: block;
		width: $width;
		height: $height;
		background: url("/dist/img/icons/#{$name}") center no-repeat;
		background-size: contain;
		background-position: center;
	}
}

@mixin icon($name, $width, $height) {

	&:after {
		content: "";
		display: block;
		width: $width;
		height: $height;
		background: url("/dist/img/icons/#{$name}") center no-repeat;
		background-size: contain;
		background-position: center;
	}
}

// Badge
@mixin badge($bg-color: $red, $text-color: white, $size: 16px, $font-size: 12px) {
	@include flexCenter();
	width: $size;
	min-width: $size;
	height: $size;
	min-height: $size;
	background-color: $bg-color;
	border-radius: 50%;
	color: $text-color;
	font-size: $font-size;
}

// Circle
@mixin circle($color: $primary, $size: 10px) {
	display: block;
	width:	$size;
	height:	$size;
	min-width: $size;
	min-height: $size;
	background-color: $color;
	border-radius: 50%;
}

// Truncate text with "..."
@mixin truncate($width) {
	max-width: $width;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


// Functions for centering
@mixin absCenterY() {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin absCenterX() {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin absCenter() {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin marginCenter {
	margin: { 
		left: auto;
		right: auto;
	}
}

@mixin flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

// Placeholder
@mixin placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}

// Disabled
@mixin disabled() {
	background: #EDEDED !important;
	border: 1px solid #F2F2F2 !important;
	cursor: not-allowed !important;
}

// Font 
/* !LOAD FONTS IN HTML, IT'S FASTER! */ 
@mixin font($font-family, $font-file, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot') format('embedded-opentype'),
         url($font-file+'.woff') format('woff'),
         url($font-file+'.ttf') format('truetype'),
 	;
    font-weight: $font-weight;
    font-style: $font-style;
  }
}


// Custom scrollbar
@mixin scrollbar($color: $secondary) {

	::-webkit-scrollbar {
	  width: 6px;
	  height: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	  background: #F2F2F2;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-radius: 5px;
	  background: $color;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: $color;
	}

}