@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';


.quill {
    display: grid;
    border-top-left-radius: $border-radius !important;
    border-top-right-radius: $border-radius !important;

    .ql-toolbar {
        border-top-left-radius: $border-radius !important;
        border-top-right-radius: $border-radius !important;
    }

    .ql-editor {
        min-height: 350px;
    }

    .ql-container {
        border-bottom-left-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;
    }
}

.quill-content {
    p {
        margin-bottom: 0;
    }

    img {
        @extend .img-fluid;
    }
}