.why-us-card {
    .why-us-card-header {
        margin-bottom: 0.75rem;

        @include md-up {
            margin-bottom: 1.5rem;
        }
    }

    .why-us-card-title {
        font-size: 1.25rem;
        line-height: 1.3;
        letter-spacing: -1px;
        color: #353c45;
        font-weight: 500;
        margin-bottom: 0;

        @include md-up {
            font-size: 1.5rem;
            line-height: 1.42;
            letter-spacing: -1.2px;
        }
    }

    .why-us-card-text {
        line-height: 1.88;
        letter-spacing: -0.48px;
    }
}
