@mixin circle($size) {
    content: '';
    position: absolute;
    border-radius: 50%;
    left: calc(50% - #{$size/2});
    top: calc(50% - #{$size/2});
    width: calc($size - 2px);
    height: $size;
}

$size: 166px;
$sizeSmall: 80px;

.progress-pie-chart {
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: #e5e5e5;
    position: relative;

    &.gt-50 {
        background-color: $red;

        .ppc-percents {
            span {
                color: $red;
            }
        }
    }

    &.gt-80 {
        background-color: $green;

        .ppc-percents {
            span {
                color: $green;
            }
        }
    }

    .ppc-percents {
        @include circle(#{$size/1.15});
        background: #fff;
        text-align: center;

        span {
            display: block;
            font-size: 3rem;
            font-weight: bold;
            line-height: 1.1;
            color: $red;
        }
    }

    .pcc-percents-wrapper {
        display: table-cell;
        vertical-align: middle;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .pcc-text {
        font-size: 0.75rem !important;
        color: #353c45 !important;
        position: relative;
        //margin-top: -10px;
    }

    .percent {
        position: relative;
        font-size: 1.5rem !important;
        margin-bottom: -15px;
        margin-left: 5px;
    }

    &.small {
        width: $sizeSmall;
        height: $sizeSmall;

        .ppc-percents {
            @include circle(#{$sizeSmall/1.15});

            span {
                font-size: 1rem;
            }
        }

        .percent {
            position: relative;
            font-size: 1rem !important;
            margin-bottom: -0px;
            margin-left: 0px;
        }
    }
}
.ppc-progress {
    @include circle($size);
    clip: rect(0, $size, $size, 84px);

    .ppc-progress-fill {
        @include circle($size);
        clip: rect(0, #{$size/2}, $size, 0);
        background: $red;
        transform: rotate(60deg);
        transition: transform 0.5s ease-in-out;
    }

    .gt-50 & {
        clip: rect(0, #{$size/2}, $size, 0);

        .ppc-progress-fill {
            clip: rect(0, $size, $size, 84px);
            background: #e5e5e5;
        }
    }
}

.ppc-progress-small {
    @include circle($sizeSmall);
    clip: rect(0, $sizeSmall, $sizeSmall, #{$sizeSmall/2});

    .ppc-progress-fill {
        @include circle($sizeSmall);
        clip: rect(0, #{$sizeSmall/2}, $sizeSmall, 0);
        background: $red;
        transform: rotate(0deg);
    }

    .gt-50 & {
        clip: rect(0, #{$sizeSmall/2}, $sizeSmall, 0);

        .ppc-progress-fill {
            clip: rect(0, $sizeSmall, $sizeSmall, #{$sizeSmall/2});
            background: #e5e5e5;
        }
    }
}

.percent-value {
    letter-spacing: -3px;
}
