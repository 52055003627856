#cm.cloud {
    border-radius: 0px !important;
}

#s-inr {
    border-radius: 10px;
}

.c-bl {
    button {
        border-radius: 10px !important;
    }
}

#c-bns {
    button {
        border-radius: 10px !important;
    }
}

#s-bns,
#s-bl,
#s-c-bnc {
    button,
    .c-bl.b-ex,
    #s-c-bn {
        border-radius: 10px !important;
    }
}

#s-all-bn,
#c-p-bn {
    background: $primary !important;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
    background: $primary !important;
}

#cm.cloud {
    width: 100%;
    max-width: 100vw;
    right: unset;
    left: unset;
    bottom: 0;
    background-color: rgba(white, 0.9);
    overflow: unset !important;

    &:after {
        content: '';
        display: block;
        width: 144px;
        height: 144px;
        background: url('/dist/img/cookies_bar_image.png') no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.cc_div .cloud #c-inr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.cloud #c-ttl,
.cc_div .cloud #c-txt {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

.cloud #c-ttl {
    display: none;
}

#s-bns {
    padding-top: 30px;
    padding-bottom: 0;
}

.cc_div .cloud #c-bns,
#s-bns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    grid-auto-flow: row;
    grid-template-areas:
        'accept accept'
        'setting reject';
    margin: 40px 0 30px;
    height: auto;

    .accept {
        grid-area: accept;
        height: 56px !important;
        margin: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .setting {
        grid-area: setting;
        background: #b4b4b4 !important;
        color: #fff;
        height: 56px !important;
        margin: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        &:hover {
            color: white;
            background: #898989 !important;
        }
    }

    .reject {
        grid-area: reject;
        background: #f3f3f3 !important;
        border: 1px solid #e1e1e1;
        color: #8e8e8e;
        height: 56px !important;
        margin: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
