.for-companies {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/dist/img/for_companies.png') center no-repeat;
    background-size: cover;
}

.for-individuals {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/dist/img/for_individuals.png') center no-repeat;
    background-size: cover;
}

.category-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 28px;
    background-color: black;
    height: 100%;
    border-radius: 18px;

    @include md-up {
        padding: 64px;
    }

    .category-card-title {
        font-size: 1.3125rem;
        font-weight: 500;
        line-height: 1.52;
        letter-spacing: -0.63px;
        margin-bottom: 190px;

        @include md-up {
            margin-bottom: 260px;
        }
    }

    .category-card-subtitle {
        font-size: 1.75rem;
        line-height: 1.36;
        letter-spacing: -1.4px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .category-card-text {
        line-height: 1.75;
        letter-spacing: -0.48px;
        text-align: left;
        color: #fff;
        margin-bottom: 2rem;
    }
}
