.course-card {
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(#707070, .2);
    margin-bottom: 32px;

    &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
    }

    &.big {
        img {
            @include lg-up {
                width: 300px !important;
                min-width: 300px !important;
                max-width: 300px !important;
                height: 100%;
                margin-bottom: 0;
            }
        }

        .course-card-img {
            @include lg-up {
                margin-right: 40px;
                margin-bottom: 0;
            }
        }

        .course-card-title {

            @include lg-up {
                font-size: 1.5rem;
            }
        }
    }
}

.course-card-img {
    margin-bottom: 1rem;

    img {
        height: 175px;
        object-fit: cover;
        border-radius: 12px;
    }
}

.course-card-labels {
    margin-bottom: 1rem;

    .label {
        display: inline-flex;
        align-items: center;
        justify-content: center;    
        height: 22px;
    }
}

.course-card-title {
    font-size: 1.25rem;
    line-height: 1.3;
    letter-spacing: -1px;
    font-weight: 500;
    margin-bottom: 0.75rem;
}

.course-card-ratings {
    margin-bottom: 1.5rem;
}

.course-card-description {
    line-height: 1.88;
    letter-spacing: -0.48px;
    margin-bottom: 2rem;
}

.price {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.72px;
    color: $red;
    margin-top: 5px;

    &.old {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.48px;
        color: #353c45;
        line-height: 1;

        &:after {
            content: '';
            display: block;
            position: absolute;
            background-color: $red;
            height: 1px;
            width: calc(100% + 16px);
            top: calc(50% - 2px);
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &.text-dark {
            color: #353c45;

            &:after {
                background-color: #353c45;
            }
        }
    }
}
