.countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 3px 3px 20px rgba(#0a0d38, 0.16);
    border-radius: 12px;
    padding: 45px 32px;

    .countdown-time {
        font-size: 3rem;
        color: rgba(#353c45, 0.6);
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.countdown-mobile {
    color: rgba(#353c45, 0.6);
}