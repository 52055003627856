.label {
    padding: 3px 14px;
    line-height: 22px;
    letter-spacing: -0.36px;
    font-size: 12px;
    border-radius: 3px;
    height: 22px;

    &.bg-red {
        background-color: $red;
        color: white;
    }

    &.bg-blue {
        background-color: $blue;
        color: white;
    }

    &.bg-green {
        background-color: $green;
        color: white;
    }

    &.bg-orange {
        background-color: #FF9D00;
        color: white;
    }
}

.label-big {
    padding: 10px 24px;
    border-radius: 16px;
    color: white;
    background-color: #353c45;
    letter-spacing: -0.36px;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
}
