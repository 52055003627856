.modal-dialog {
    margin: 0;

    @include sm-up {
        margin: 1.75rem auto;
    }
}

.modal-dialog-centered {
    min-height: calc(100% - 44px);
}

.modal-dialog {
    @include md-up {
        max-width: 700px;
    }
}

.modal-content {
    border: 0;
    padding-bottom: 72px;
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0;
    padding-top: 72px;

    @include sm-up {
        width: auto;
        height: auto;
        left: unset;
        top: unset;
        width: 100%;
        border-radius: 12px;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 32px;
        padding-right: 32px;
    }

    @include md-up {
        min-width: 700px;
        padding-left: 70px;
        padding-right: 70px;
    }

    .btn-close {
        position: absolute;
        top: 36px;
        right: 32px;
    }

    .btn {
        width: 100%;

        @include md-up {
            width: auto;
        }
    }
}

.modal-body {
    padding: 22px;
}



.modal-form {
    margin-top: 32px;

    .form-control {
        width: 100%;

        @include sm-up {
            max-width: 400px;
            width: 400px;
        }
    }
}
