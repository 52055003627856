.video-container {
    padding: 14px;
    border-radius: 18px;
    box-shadow: 3px 3px 20px 0 rgba(15, 17, 48, 0.14);
    background-color: #fff;

    iframe {
        border-radius: 16px;
    }
}
