.quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 620px;
    color: #353c45;

    @include md-up {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }

    &:before {
        content: '';
        display: block;
        background: url('/dist/img/icons/quote_icon_red.svg') center no-repeat;
        background-size: contain;
        min-width: 20px;
        min-height: 20px;
        margin-bottom: 14px;
        
        @include md-up {
            margin-bottom: 0;
        }
    }

}

.quote-container {
    position: relative;
    max-width: 650px;
    
    .icon-arrow-down-red {
        position: absolute;
        right: 70px;
        bottom: 70px;
    }
}