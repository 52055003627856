.reviews {

    .tns-controls {
        justify-content: flex-start;
    }
}

.review {
    border-bottom: 1px solid rgba(#353c45, 0.15);
    padding-bottom: 32px;
    margin-bottom: 32px;

    &:last-child {
        border-bottom-color: transparent;
        margin-bottom: 0;
    }
}

.review-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.88;
        letter-spacing: -0.48px;
        text-align: left;
        color: #353c45;
        margin-bottom: 0;
    }

    &:before {
        content: '';
        display: block;
        background: url('/dist/img/icons/quote_icon_red.svg') center no-repeat;
        background-size: contain;
        min-width: 20px;
        min-height: 20px;
        margin-right: 8px;
        transform: scale(-1, -1);
    }
}

.review-author {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 20px;
}

.review-card-border {
    border-bottom: 1px solid rgba(#707070, .2);
}