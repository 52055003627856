$primary: #c4432e;
$secondary: #353c45;
$green: #00aa2e;
$blue: #007fc3;
$yellow: #ff9d00;

$red: #c4432e;
$success: $green;
$danger: #d14142;

$grey: #f4f5f5;
$grey-dark: rgba(#353c45, 0.7);

$dark: #353c45;
$light: $grey;

$gold: #ff9d00;

$breadcrumb-divider: quote('>');
$breadcrumb-divider-color: #bbbec2;

$border-radius: 0.75rem;

