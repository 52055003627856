.container {
    padding-left: 22px;
    padding-right: 22px;
}

.container-fluid {
    padding-left: 22px;
    padding-right: 22px;

    @include lg-up {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

