html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

hr {
    background-color: rgba(#707070, 0.4);
}

.w-lg-auto {
    @include lg-up {
        width: auto !important;
    }
}

.mw-200 {
    min-width: 200px;
}

.draggable {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-bottom: 0;
    cursor: grab;

    &::-webkit-scrollbar {
        display: none;
    }
}

.mw-260 {
    min-width: 260px;
}

.mw-md-260 {
    @include md-up {
        min-width: 260px !important;
    }
}