.breadcrumb {
    margin-bottom: 50px;

    @include lg-up {
        margin-bottom: 60px;
    }
}

.breadcrumb-item {
    font-size: 0.875rem;

    a {
        text-decoration: none;
        color: $dark;
    }

    &.active {
        color: $dark;
        font-weight: 500;
    }
}
