.main-hero-img-container {
    @include xl-up {
        margin-left: -40px;
    }
}

.main-hero-text {
    max-width: 580px;
    line-height: 2;
    letter-spacing: -0.48px;
}

.main-hero-title {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.21;
    letter-spacing: -1.4px;

    strong {
        font-weight: 500 !important;
        color: $primary !important;
    }

    @include md-up {
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 1.04;
        letter-spacing: -3.6px;
    }

    @include lg-up {
        font-size: 3.5rem;
    }

    @include xl-up {
        font-size: 4.5rem;
    }
}

.main-hero {
    position: relative;

    @include lg-up {
        padding-top: 40px;
        padding-bottom: 140px;
        min-height: 610px;
    }

    @include xxl-up {
        padding-bottom: 210px;
    }

    .icon-arrow-down-big {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }

    .show-more {
        position: absolute;
        bottom: 0;
        left: 0;

        &:hover {
            .icon-arrow-down-grey {
                @include rounded-icon('arrow_down_icon_white.svg', 10px, 10px, #353c45, #353c45);
            }
        }

        .btn-show-more {
            width: 48px;
        }

        .btn-show-more-text {
            position: relative;
            top: -45px;
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
    }
}
