.login-form {
    border-radius: 12px;
    max-width: 700px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;
    margin-bottom: 28px;
    padding: 72px 22px;

    @include md-up {
        padding: 120px 140px;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    h1 {
        font-size: 1.5rem;
        margin-bottom: 40px;

        @include md-up {
            margin-bottom: 64px;
        }
    }

    label {
        margin-bottom: 10px;
    }
}
