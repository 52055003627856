.exam-result {
    .title {
        font-size: 1.75rem;
        max-width: 620px;
        margin-bottom: 28px;
        letter-spacing: -2px;

        @include md-up {
            font-size: 3rem;
            margin-bottom: 56px;
        }
    }

    .text-big {
        font-weight: 600;
        font-size: 1.25rem;
        letter-spacing: -1px;

        @include xl-up {
            font-size: 1.5rem;
        }
    }
}

.icon-exam-passed {
    &:after {
        content: '';
        display: block;
        width: 42px;
        height: 42px;
        background: url('/dist/img/exams/exam-passed.svg') center no-repeat;
        background-size: contain;
        background-position: center;

        @include md-up {
            height: 70px;
            width: 70px;
        }
    }
}

.icon-exam-failed {
    &:after {
        content: '';
        display: block;
        width: 42px;
        height: 42px;
        background: url('/dist/img/exams/exam-failed.svg') center no-repeat;
        background-size: contain;
        background-position: center;

        @include md-up {
            height: 70px;
            width: 70px;
        }
    }
}

.course-info {
    border-bottom: rgba(#707070, 0.2) solid 1px;
    padding-bottom: 42px;
    margin-bottom: 42px;

    @include md-up {
        padding-bottom: 52px;
        margin-bottom: 52px;
    }

    .course-img-container {
        margin-bottom: 32px;
        width: 100%;

        img {
            width: 100%;
        }

        @include md-up {
            margin-right: 50px;
            margin-bottom: 0;
            height: 100%;
            max-width: 200px;
        }
    }

    p {
        line-height: 34px;
    }
}

.exam-warning {
    box-shadow: 3px 3px 20px rgba(#0a0d38, 0.16);
    border-radius: 12px;
    padding: 52px 20px;

    @include md-up {
        padding: 100px;
    }

    .title {
        font-size: 20px;
        margin-bottom: 24px;

        @include md-up {
            font-size: 1.5rem;
            margin-bottom: 26px;
        }
    }

    p {
        margin-bottom: 28px;
        color: #353c45;

        @include md-up {
            max-width: 660px;
            margin-bottom: 42px;
        }
    }
}

.question-box {
    box-shadow: 3px 3px 20px rgba(#0a0d38, 0.16);
    border-radius: 12px;
    padding: 36px 28px;
    margin-bottom: 20px;

    @include md-up {
        padding: 70px 100px;
    }

    .title {
        font-size: 20px;
        margin-bottom: 24px;

        @include md-up {
            font-size: 1.5rem;
            margin-bottom: 26px;
        }
    }

    .question-number {
        font-size: 3rem;
        line-height: 1;
    }

    p {
        margin-bottom: 28px;
        color: #353c45;

        @include md-up {
            max-width: 660px;
            margin-bottom: 42px;
        }
    }

    .form-check {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
