$profile-picture-width: 80px;
$profile-picture-height: 80px;

.small-profile-picture {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid lightgray;
}

.e-profile-picture {
    height: $profile-picture-height;
    min-height: $profile-picture-height;
    width: $profile-picture-width;
    min-width: $profile-picture-width;
    border-radius: 50%;
    object-fit: cover;

    &.big {
        height: calc(#{$profile-picture-height} * 1.1);
        min-height: calc(#{$profile-picture-height} * 1.1);
        width: calc(#{$profile-picture-width} * 1.1);
        min-width: calc(#{$profile-picture-width} * 1.1);

        @include md-up {
            height: calc(#{$profile-picture-height} * 1.5);
            min-height: calc(#{$profile-picture-height} * 1.5);
            width: calc(#{$profile-picture-width} * 1.5);
            min-width: calc(#{$profile-picture-width} * 1.5);
        }
    }

    &.small {
        height: calc(#{$profile-picture-height} * 0.75);
        min-height: calc(#{$profile-picture-height} * 0.75);
        width: calc(#{$profile-picture-width} * 0.75);
        min-width: calc(#{$profile-picture-width} * 0.75);

        @include md-up {
            height: calc(#{$profile-picture-height} * 0.75);
            min-height: calc(#{$profile-picture-height} * 0.75);
            width: calc(#{$profile-picture-width} * 0.75);
            min-width: calc(#{$profile-picture-width} * 0.75);
        }
    }
}

.e-profile-picture-container {
    height: $profile-picture-height;
    width: $profile-picture-width;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        height: $profile-picture-height;
        width: $profile-picture-width;
        border-radius: 50%;
        background: rgba(black, 0.25);
    }

    .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
    }
}

.user-card {
    hr {
        background: lightgrey;
        margin: 0.5rem -16px;

        @include sm-up {
            margin: 0.5rem -24px;
        }
    }
}
