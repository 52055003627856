.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    min-width: 58px;
    height: 58px;
    min-height: 58px;
    border-radius: 6px;
    box-shadow: 3px 3px 10px 0 rgba(15, 17, 48, 0.1);
    background-color: #fff;
}

.icon-arrow-down-big {
    @include icon('arrow_down_icon_big.svg', 146px, 170px);
}
.icon-user-white {
    @include icon('user_icon_white.svg', 40px, 40px);
}

.icon-filter-grey {
    @include icon('filter_icon_grey.svg', 16px, 16px);
}

.icon-user-grey {
    @include icon('user_icon_grey.svg', 40px, 40px);
}

.icon-cart-red {
    @include icon('cart_icon_red.svg', 40px, 40px);
}

.icon-cart-white {
    @include icon('cart_icon_white.svg', 18px, 18px);
}

.icon-arrow-right-white {
    @include icon('arrow_right_icon_white.svg', 24px, 12px);
}

.icon-arrow-right2-white {
    @include icon('arrow_right2_icon_white.svg', 24px, 12px);
}

.icon-footer-pause {
    @include icon('footer_pause_icon.svg', 130px, 130px);

    @include md-up {
        @include icon('footer_pause_icon.svg', 180px, 180px);
    }
}

.icon-footer-play {
    @include icon('footer_play_icon.svg', 130px, 130px);

    @include md-up {
        @include icon('footer_play_icon.svg', 180px, 180px);
    }
}

.icon-arrow-up-white {
    @include rounded-icon('arrow_up_icon_white.svg', 10px, 10px);
    transition: .2s all;
    
    &:hover {
        @include rounded-icon('arrow_up_icon_grey.svg', 10px, 10px, #353c45, white);
    }
}

.icon-arrow-down-grey {
    @include rounded-icon('arrow_down_icon_grey.svg', 10px, 10px, #353c45);
    transition: .2s all;

    &:hover {
        @include rounded-icon('arrow_down_icon_white.svg', 10px, 10px, #353c45, #353c45);
    }
}

.icon-arrow-down-red {
    @include icon('arrow_down_icon_red.svg', 43px, 152px);
}

.icon-company-white {
    @include icon('company_icon_white.svg', 21px, 21px);
}

.icon-individual-white {
    @include icon('individual_icon_white.svg', 8px, 21px);
}

.icon-star-yellow {
    @include icon('star_icon_yellow.svg', 14px, 14px);
}

.icon-time-black {
    @include icon('time_icon_black.svg', 14px, 14px);
}

.icon-time-black {
    @include icon('time_icon_black.svg', 14px, 14px);
}

.icon-video-black {
    @include icon('video_icon_black.svg', 14px, 14px);
}

.icon-price-tag-red {
    @include icon('price_tag_icon_red.svg', 22px, 22px);
}

.icon-price-tag-green {
    @include icon('price_tag_icon_green.svg', 22px, 22px);
}

.icon-price-tag-dark {
    @include icon('price_tag_icon_dark.svg', 22px, 22px);
}

.icon-download {
    @include icon('download_icon.svg', 14px, 14px);
}


.icon-clock-grey {
    @include icon('clock_icon_grey.svg', 20px, 20px);
    opacity: .6;
}

.icon-clock {
    @include icon('clock_icon_grey.svg', 20px, 20px);
}

.icon-clock-white {
    @include icon('clock_icon_white.svg', 20px, 20px);
}

.icon-close-red {
    @include icon('close_red.svg', 14px, 14px);
}

.icon-card-grey {
    position: relative;
    margin-top: -3px;
    @include icon('card_icon_grey.svg', 16px, 20px);
}

.icon-delivery-grey {
    position: relative;
    margin-top: -3px;
    @include icon('delivery_grey.svg', 24px, 32px);
}

.icon-note-grey {
    position: relative;
    margin-top: -3px;
    @include icon('note_grey.svg', 18px, 18px);
}


.icon-user-small-grey {
    position: relative;
    margin-top: -3px;
    @include icon('user_grey.svg', 16px, 20px);
}


.icon-success {
    @include icon('success_green.svg', 70px, 70px);
}

.icon-error {
    @include icon('error_red.svg', 70px, 70px);
}