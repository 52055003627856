


.cart-item {
    border-bottom: 1px solid rgba(#707070, 0.2);
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.cart-coupon {
    border-bottom: 1px solid rgba(#707070, 0.2);
    padding-bottom: 40px;
    margin-bottom: 40px;

    @include md-up {
        padding-bottom: 80px;
        margin-bottom: 80px;
    }

    .form-control {
        border: 1px solid rgba(#707070, 0.2);

        @include placeholder {
            color: #e6e6e6;
        }

        @include md-up {
            width: 350px;
        }
    }
}

.cart-payments {
    border-bottom: 1px solid rgba(#707070, 0.2);

    padding-bottom: 40px;
    margin-bottom: 40px;

    @include md-up {
        padding-bottom: 80px;
        margin-bottom: 80px;
    }
}
