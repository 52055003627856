.footer {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: $secondary;

    .nav-link {
        font-size: 1rem;
        color: white;
        line-height: 2;
        padding: 0;
        letter-spacing: -0.5px;
        transition: 0.1s padding-left;

        &:hover {
            padding-left: 5px;
        }
    }
}

.footer-title {
    color: white;
    font-size: 1.25rem;
    margin-bottom: 24px;
    letter-spacing: -1px;

    @include md-up {
        font-size: 1.5rem;
        line-height: 1.42;
        letter-spacing: -1.2px;
        margin-bottom: 36px;
    }
}

.footer-bottom {
    padding-top: 25px;
    border-top: solid 0.5px rgba(white, 0.3);
    margin-top: 30px;
}

.copyright-text {
    letter-spacing: -0.48px;
    color: rgba(white, 0.5);
}

.footer-icons {
    margin-bottom: -65px;
    width: 150px;
    margin-left: auto;

    @include md-up {
        margin-bottom: -70px;
    }

    .icon {
        position: relative;
    }

    .icon-footer-pause {
        display: block;
        margin-bottom: -50px;
        margin-left: -16px;

        @include md-up {
            margin-bottom: -80px;
            margin-left: -40px;
        }
    }
}

.footer-mt {
    margin-top: 100px;

    @include md-up {
        margin-top: 150px;
    }
}

.footer-logo {
    max-width: 150px;
}