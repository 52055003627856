.form-control {
    padding: 19px 25px;
    color: #353c45;
    font-weight: 500;

    &.small {
        padding: 0.5rem 0.75rem;
    }

    &.smaller {
        padding: 1rem 0.75rem;
    }

    @include placeholder {
        color: #e6e6e6;
    }
}

.form-select {
    padding: 19px 25px;
    color: #353c45;
    font-weight: 500;
}

label {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;
}

.form-control[type='file'] {
    padding: 0.375rem 0.75rem;
}

.form-check {
    label {
        display: block;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 0;
    }
}

.input-number-decrement {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 56px;
    height: 56px;
    background-color: #efefef !important;
    line-height: 0;
    font-size: 36px;
    padding-bottom: 17px;
    margin-right: 6px;
    padding: 0;

    &:hover {
        background-color: darken(#efefef, 5) !important;
    }
}

.input-number-increment {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 56px;
    height: 56px;
    background-color: $primary !important;
    color: white;
    font-size: 36px;
    line-height: 0;
    padding-bottom: 17px;
    margin-left: 6px;
    padding: 0;

    &:hover {
        color: white;
        background-color: darken($primary, 5) !important;
    }
}

.input-number {
    width: 87px;
    height: 56px;
    border: 1px rgba(#707070, 0.2) solid;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
}
