.tns-container {
    margin-top: 50px;

    @include lg-up {
        margin-top: 64px;
        margin-bottom: 0;
    }
}

.tns-nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;

    @include lg-up {
        margin-bottom: 0;
    }

    .number {
        font-size: 0.875rem;
    }

    button {
        background-color: rgba(#353c45, 0.2);
        height: 1px;
        width: 33px;
        border: 0;

        &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        &.tns-nav-active {
            background-color: $primary;
        }
    }
}

.tns-nav {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tns-controls {
    display: flex;
    justify-content: center;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        min-width: 48px;
        height: 48px;
        min-height: 48px;
        border-radius: 50%;
        border: solid 1px $secondary;
        background: transparent;
        transition: .2s all;;

        &:hover {
            background-color: $secondary;

            &:first-child {

                &:after {
                    background: url(/dist/img/icons/arrow_left_icon_white.svg) center no-repeat;
                }
            }

            &:last-child {

                &:after {
                    background: url(/dist/img/icons/arrow_right_icon_white.svg) center no-repeat;
                }
            }
        }

        &:first-child {
            margin-right: 10px;

            &:after {
                content: '';
                display: block;
                background: url(/dist/img/icons/arrow_left_icon_grey.svg) center no-repeat;
                width: 8px;
                height: 10px;
            }
        }

        &:last-child {

            &:after {
                content: '';
                display: block;
                background: url(/dist/img/icons/arrow_right_icon_grey.svg) center no-repeat;
                width: 8px;
                height: 10px;
            }
        }
    }
}

.tns-container {
    @include lg-up {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
