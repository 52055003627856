.info-page {
    .text-title {
        max-width: 680px;
        font-size: 1.5rem;
        margin-bottom: 20px;

        @include md-up {
            font-size: 3rem;
            margin-bottom: 3rem;
        }
    }

    hr {
        border-bottom: rgba(#707070, 0.2) 1px solid;
        margin: 40px 0;

        @include md-up {
            margin: 80px 0;
        }
    }
}