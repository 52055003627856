#collapseFilter {
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(#353c45, 0.15);

    @include lg-up {
        padding-bottom: 0;
        border-bottom: 0;
    }

    h5 {
        margin-top: 46px;
    }
}