.faq {
    background-color: white;
    transition: 0.2s all;

    .collapse-icon {
        top: 0%;
    }

    .btn-collapse-faq-text {
        padding-right: 70px;
        font-size: 1rem !important;

        @include md-up {
            font-size: 1.5rem !important;
        }
    }

    &.active {
        background-color: $primary !important;

        .btn-collapse-faq-text {
            color: white !important;
        }

        p {
            color: white !important;
        }
    }

    &.active-title {
        .btn-collapse-faq-text {
            color: $red !important;
        }
    }
}