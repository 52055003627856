.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 0.5rem;
    padding: 0.625rem 1.125rem;
    line-height: 1;
    transition: 0.2s all;

    &.big {
        padding: 1.25rem 2.25rem;
    }

    &.bigger {
        padding: 1rem 2.25rem;
    }

    .icon-arrow-right-white {
        transition: 0.1s padding-left;
    }

    &:hover {
        .icon-arrow-right-white {
            padding-left: 10px;
        }
    }
}

.btn-primary {
    &:hover {
        background-color: darken($primary, 5);
    }

    &.disabled,
    &:disabled {
        background-color: $primary;
        color: white;
        border: 0;
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.btn-success {
    background-color: #41af79;
    color: white;
    border: 0;

    &:hover,
    &:active,
    &:focus {
        color: white;
        background-color: darken(#41af79, 5);
        border: 0;
    }

    &.disabled,
    &:disabled {
        background-color: #41af79;
        color: white;
        border: 0;
        opacity: 0.5;
        cursor: not-allowed !important;
    }
}

.btn-green {
    background-color: #00aa2e !important;
    color: white;
    border: 0;

    &:hover,
    &:active {
        color: white;
        background-color: darken(#00aa2e, 5) !important;
        border: 0;
    }
}

.btn-collapse-filter {
    &[aria-expanded='true'] {
        .icon-filter-grey {
            &:after {
                background-image: url('/dist/img/close.svg') !important;
                transform: rotate(180deg);
            }
        }
    }
}

.btn-collapse-lecture {
    position: relative;
    letter-spacing: -1px;
    line-height: 30px;

    .collapse-icon {
        position: absolute;
        top: 20px;
        right: 0;
    }

    @include lg-up {
        padding-right: 112px !important;

        .btn-collapse-lecture-text {
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    &[aria-expanded='true'] {
        .collapse-icon {
            transform: rotate(180deg);
        }
    }
}

.collapse-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    border-radius: 50%;
    border: solid 1px $secondary;
    background: transparent;
    transition: 0.2s all;

    &.white {
        border: solid 1px white;

        &:after {
            content: '';
            display: block;
            background: url(/dist/img/icons/arrow_left_icon_white.svg) center no-repeat;
            transform: rotate(-90deg);
            width: 8px;
            height: 10px;
        }

        &:hover {
            background-color: white;

            &:after {
                content: '';
                display: block;
                background: url(/dist/img/icons/arrow_left_icon_grey.svg) center no-repeat;
                transform: rotate(-90deg);
                width: 8px;
                height: 10px;
            }
        }
    }

    &:after {
        content: '';
        display: block;
        background: url(/dist/img/icons/arrow_left_icon_grey.svg) center no-repeat;
        transform: rotate(-90deg);
        width: 8px;
        height: 10px;
    }

    &:hover {
        background-color: $secondary;

        &:after {
            background: url(/dist/img/icons/arrow_left_icon_white.svg) center no-repeat;
            transform: rotate(-90deg);
        }
    }
}

.filter-buttons {
    overflow-x: auto;
    padding: 10px;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 32px;

    @include md-up {
        margin-bottom: 0;
    }
}

.btn-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: white;
    margin-right: 8px;
    box-shadow: 3px 3px 10px rgba(#0f1130, 0.1);

    &:last-child {
        margin-right: 0;
    }

    &.active,
    &:hover {
        color: white;
        background-color: $primary;
    }
}

.btn-collapse-course {
    .course-img {
        width: 165px;
        height: 90px;
        object-fit: cover;
        margin-bottom: 24px;

        @include lg-up {
            margin-right: 32px;
            margin-bottom: 0;
        }
    }
}

.btn-collapse-team {
    margin-bottom: 32px;

    @include lg-up {
        margin-bottom: 0;
    }
}
