.contact-form-alert {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $dark;
    padding: 32px;
    display: none;
    opacity: 0;
    transition: 0.35s all;
    z-index: -1;

    &.show {
        opacity: 1;
        z-index: 9;
    }

    .contact-form-alert-title {
        color: white;
        font-size: 1.25rem;
        font-weight: bold;
        text-align: center;
        max-width: 400px;

        @include lg-up {
            font-size: 2rem;
        }
    }

    .contact-form-alert-svg {
        height: 96px;
        width: 96px;
    }
}

.contact-box-item {
    display: flex;
    align-items: center;
    position: relative;
    height: 95px;

    &:not(.contact-box-item--no-border) {
        &:first-child {
            @include md-up {
                padding-right: 56px;
                &:after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 95px;
                    background-color: rgba(#707070, 0.2);
                    right: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    &:nth-child(2) {
        @include md-up {
            padding-left: 56px;
        }
    }

    .icon {
        margin-right: 15px;
        margin-top: -15px;
    }
}