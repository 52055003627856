.navbar-collapse ul {
    @media (max-width: 1200px) {
        height: calc(100vh - 108px);
        overflow-y: auto;
        padding-top: 40px;
    }
}

.navbar-toggler {
    margin-right: -0.75rem;

    .navbar-toggler-icon {
        transition: 0.2s all;
    }

    &[aria-expanded='true'] {
        .navbar-toggler-icon {
            background-image: url('/dist/img/close.svg') !important;
            transform: rotate(180deg);
        }
    }

    &[aria-expanded='false'] {
        .navbar-toggler-icon {
            background-image: url('/dist/img/hamburger.svg') !important;
        }
    }
}

.navbar {
    padding: 30px 0;

    @include lg-up {
        padding: 50px 0;
    }

    .nav-link {
        color: #353c45;
        font-size: 1.25rem;
        font-weight: 500;

        @include xl-up {
            font-size: 1rem;
            font-weight: 400;
        }

        &:hover {
            color: $primary;
        }

        &.active {
            font-weight: 500;
            color: $primary;
        }
    }

    .nav-item {
        @media (min-width: 1600px) {
            margin-right: 32px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

#navbarContent {
}

.navbar-brand {
    z-index: 999;
    img {
        max-width: 120px;

        @media screen and (min-width: 370px) {
            max-width: 170px;
        }

        @include md-up {
            max-width: 200px;
        }
    }
}
