.my-acc-title {
    margin-bottom: 1rem !important;

    @include md-up {
        margin-bottom: 72px !important;
    }
}

.form-change-password {
    max-width: 460px;
}

.my-acc-nav {
    @media (max-width: 768px) {
        opacity: 0;
        position: absolute;
        z-index: 99;
        background: white;
        width: 100vw;
        min-height: 100vh;
        left: 0;
        top: 0;
        padding: 0 22px;
        height: 100vh;
        transform: translateX(-100%);
        transition: 0.35s all;
        z-index: 999;
        padding-top: 35px;

        &.show {
            opacity: 1;
            transform: translateX(0);
        }

        .my-acc-nav-header {
            margin-bottom: 50px;
        }
    }

    a {
        color: #353c45;
        font-weight: 400;
        transition: 0.2s all;

        &.active,
        &:hover {
            color: $primary;

            svg,
            svg path,
            .icon {
                fill: $primary !important;
            }
        }

        &.active {
            font-weight: 500;
        }
    }

    svg {
        width: 16px;
        height: 16px;
        margin-right: 14px;
    }
}

.my-acc-toggler {
    position: absolute;
    left: 0;
    top: 115px;
    background: $primary;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 22px 24px;

    svg {
        width: 24px;
        height: 18px;
    }
}

#myAccClose {
    background: white;
    position: absolute;
    right: 27px;
    margin: 0;
}

.document-item {
    border-bottom: 1px solid rgba(#707070, 0.2);
    padding-bottom: 32px;
    margin-bottom: 32px;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
    }

    span {
        color: #353c45;
    }

    .document-item-item {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        @include md-up {
            margin-bottom: 0;
        }
    }

    .document-item-date {
        @include md-up {
            display: flex;
            align-items: center;
            width: 20%;
            //margin-right: 100px;
        }
    }

    .document-item-title {
        @include md-up {
            width: 60%;
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    .document-item-download {
        @include md-up {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 20%;
        }
    }
}

.documents {
    .document-item-title {
        @include md-up {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.my-acc-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
