.pagination {

    .page-item {
        margin: 0 5px;

        &.active, &:hover {

            .page-link {
                background-color: $primary;
                border-color: $primary;
                color: white;
            }
        }
    }

    .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50% !important;
        border: solid 1px $black;
        color: $black;
    }
}