#loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    display: none;
    background: rgba(black, 0.4);
}

#loader {
    width: 3rem;
    height: 3rem;
    border: 10px solid #f3f3f3;
    border-top: 9px solid $primary;
    border-radius: 100%;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}